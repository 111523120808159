import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoAlt = () => {
    const data = useStaticQuery(graphql`
    query {
        image1: file(relativePath: {eq: "lul/santa-ring-logo-circle.png"}) {
            childImageSharp {
                    fixed(width: 130) {
                        ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        } 
    }
    `)

    return (

        <div>

            <Img fixed={data.image1.childImageSharp.fixed} alt="Santa Ring" className="santa-ring-logo" />

        </div>
    )
}

export default LogoAlt