import React from "react"
import { navigate } from "gatsby"
import addToMailchimp from 'gatsby-plugin-mailchimp'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    addToMailchimp(this.state.email, this.state)
      .then(() => navigate("/subscribed/"))
      .catch(error => alert(error))
  }


  render() {
    return(
        <div className="columns is-multiline is-centered">
            <div className="column" style={{paddingTop:'0'}}>

                <form
                name="Santa Walt Newsletter"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                >

                    <input type="hidden" name="form-name" value="contact" />

                    <div hidden>
                        <label>
                            Don’t fill this out:{' '}
                            <input name="bot-field" onChange={this.handleChange} />
                        </label>
                    </div>

                    <div className="field has-addons">
                        <div className="control">
                            <label className="label" htmlFor="input#subscribe.input">
                                <span style={{position: "absolute", opacity: "0"}}>Subscribe</span>
                                <input 
                                className="input"
                                type={'email'}
                                name={'email'}
                                onChange={this.handleChange}
                                id={'email'}
                                required={true}
                                placeholder="email@address.com"
                                />
                            </label>
                        </div>
                        <div className="control">
                            <button className="button is-primary has-text-light" type="submit">Subscribe</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        )
    }
}