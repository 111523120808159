import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Subscribe from "../../Subscribe/Subscribe"
import FollowUs from "../../FollowUs/FollowUs"

const ColumnFive = () => {
    const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
            rssMetadata {
                title
            }
        }
      }
    }
  `)
    
    return (
        <div style={{ justifyContent: 'center '}}>
            {/* <Link to="/" title={data.site.siteMetadata.rssMetadata.title}>
                <FooterLogo />
            </Link>
            <div classname="is-clearfix" style={{ paddingBottom: '1.5em' }}></div> */}
            <div className="subscribe">
                <div className="sitemap-heading has-text-dark title is-size-4 is-uppercase has-text-weight-bold">Stay up to date!</div>
                &nbsp;
                <p className="subtitle is-size-5">Subscribe to receive the latest news and offers for Santa Ring.</p>
                &nbsp;
                <Subscribe />
                {/* <div className="connect" style={{ paddingTop: "10px" }}>
                    <FollowUs />
                </div> */}
            </div>
        </div>
    )
}

export default ColumnFive;