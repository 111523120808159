import React from 'react'
import { Link } from 'gatsby'

import Modal from "../Modal/Modal"

import BurgerSlip from '@animated-burgers/burger-slip' 
import '@animated-burgers/burger-slip/dist/styles.css'

import Logo from "../Logo/Logo"

import "./Navbar.scss"

const NavbarInner = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'open',
              navBarMenuActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: ' ',
              navBarMenuActiveClass: ' ',
            })
      }
    )
  }

  render() {
    return (
        <div className="container">
            <div className="navbar-brand">
                {/* <Link to="/" className="navbar-logo navbar-item is-transparent" title="Santa Walt"> */}
                    <Logo />
                {/* </Link> */}
                <BurgerSlip 
                onClick={() => this.toggleHamburger()} 
                Component="div" 
                className={`${this.state.navBarActiveClass}`}
                />
            </div>

            <div
            id="navMenu"
            className={`navbar-menu navbar-end is-size-5 is-size-4-mobile ${this.state.navBarMenuActiveClass}`}
            >

              <div className="navbar-end has-text-weight-medium my-list">
                <Link to="/" activeClassName="is-active" className="navbar-link is-arrowless">
                  Home
                </Link>
                
                <Link to="/packages/" activeClassName="is-active" className="navbar-link is-arrowless">
                  Packages
                </Link>

                {/* <Link to="/faq/" activeClassName="is-active" className="navbar-link is-arrowless">
                  FAQ
                </Link> */}

                {/* <Link to="/about/" className="navbar-item" activeClassName="is-active">
                  About
                </Link> */}

                <Link to="/contact/" className="navbar-item" activeClassName="is-active">
                  Contact
                </Link>
              </div>

              {/* <div class="navbar-end">
                <div class="navbar-item">
                  <div class="field">
                    <p class="control"> */}
                      {/* <a class="button is-primary" href="https://github.com/jgthms/bulma/releases/download/0.9.1/bulma-0.9.1.zip">
                        <span>Pre-Register</span>
                      </a> */}
                      {/* <Modal />
                    </p>
                  </div>  
                </div>
              </div> */}

          </div>
      </div>
    )
  }
}

export default NavbarInner