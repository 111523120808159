import React, { Component } from "react";
import ColumnOne from "./Columns/ColumnOne"
// import ColumnTwo from "./Columns/ColumnTwo"
import ColumnThree from "./Columns/ColumnThree"
import ColumnFour from "./Columns/ColumnFour"
import ColumnFive from "./Columns/ColumnFive"
import Copyright from "./Copyright"
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <section id="footer" className="hero is-small">
        <div className="hero-body content">
          <div className="container">
            <div className="columns is-6 footer-columns">
              <div className="column is-3 is-size-5"></div>
              <div className="column is-2 is-size-5">
                <ColumnOne />
              </div>
              {/* <div className="column">
                <ColumnTwo />
              </div> */}
              {/* <div className="column is-2 is-size-5">
                <ColumnThree />
              </div> */}
              {/* <div className="column is-6">
                <ColumnFour />
              </div> */}
              <div className="column is-4">
                <ColumnFive />
              </div>
              <div className="column is-3"></div>
            </div>
            <Copyright />
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
