import React from "react"
import { Link } from "gatsby"

const ColumnOne = () => {
    
    return (
        <div>
            <ul>
                <li>
                    <Link to="/" activeClassName="is-active">
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/packages/" activeClassName="is-active">
                        Packages
                    </Link>
                </li>
                {/* <li>
                    <Link to="/faq/" activeClassName="is-active">
                        FAQ
                    </Link>
                </li>
                <li>
                    <Link to="/about/" activeClassName="is-active">
                        About
                    </Link>
                </li> */}
                <li>
                    <Link to="/contact/" activeClassName="is-active">
                        Contact
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default ColumnOne;
