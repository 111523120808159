import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
    const data = useStaticQuery(graphql`
    query {
        image1: file(relativePath: {eq: "lul/santa-ring-logo-circle.png"}) {
            childImageSharp {
                    fixed(width: 130) {
                        ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        } 
    }
    `)

    return (

        <Link to="/" className="navbar-logo navbar-item is-transparent" title="Santa Ring">

            <Img fixed={data.image1.childImageSharp.fixed} alt="Santa Ring" className="santa-ring-logo" />

        </Link>
    )
}

export default Logo