import React, { Component } from "react"
import { Link } from 'gatsby'

import Logo from "../Logo/Logo"

class Copyright extends Component {
  render() {
    return (
        <div id="copyright" style={{ padding: "10px 0" }}>
              {/* <Link to="/" className="navbar-logo navbar-item is-transparent" title="Santa Walt"> */}
                  <Logo />
              {/* </Link> */}
              <div className="is-size-7 has-text-centered">
                &copy; {new Date().getFullYear()} Santa Ring
              </div>
              <div className="is-size-7 has-text-centered">
                Web App built by Establish Solutions, LLC.
              </div>
        </div>
    );
  }
}

export default Copyright;
