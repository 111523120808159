const config = {
  siteTitle: "Santa Walt",
  siteTitleShort: "SantaWalt",
  siteTitleAlt: "Santa Walt",
  siteLogo: "/static/favicon/256x256.png",
  siteUrl: "https://santa-walt.com",
  pathPrefix: "",
  siteDescription: "Santa Walt is your safe, super-fun, personalized, and affordable way to visit with Santa from the comfort of your home!",
  siteRss: "/rss.xml",
  siteRssTitle: "Santa Walt RSS feed",
  siteFBAppID: "",
  googleAnalyticsID: "G-5TY9B457PG",
  disqusShortname: "https-vagr9k-github-io-gatsby-advanced-starter",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM DD, YYYY",
  postsPerPage: 5,
  userName: "Santa Walt",
  userEmail: "santawalt@gmail.com",
  userTwitter: "santawalt",
  userLocation: "Louisville, Kentucky",
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/Vagr9K/gatsby-advanced-starter",
      iconClassName: "fa fa-github"
    },
    {
      label: "Twitter",
      url: "https://twitter.com/Vagr9K",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "mailto:vagr9k@gmail.com",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "Copyright © 2020 Santa Walt", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#7f2b2b", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
